import commons from './commons'
import main from './main'
import notFound from './not-found'
import feedback from './feedback'
import cameraPermission from './cameraPermission'
import guidelines from './guidelines'
import footer from './footer'

export default {
  commons,
  main,
  notFound,
  feedback,
  cameraPermission,
  guidelines,
  footer,
}
