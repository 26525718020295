/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { node, bool } from 'prop-types'
import { I18nextProvider } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { Container } from '@creditas/layout'
import { Global } from '@creditas/global'
import i18n from 'src/i18n'
import { Header } from './Header'
import { Footer } from './Footer'
import { containerStyle } from './layout.style'

const propTypes = {
  children: node,
  hideHeader: bool,
  hideFooter: bool,
}

const Layout = ({ children, hideHeader = false, hideFooter = false }) => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={{ ...tokens }}>
      <Helmet>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        />
      </Helmet>
      <Global />
      <Header hide={hideHeader} />
      <Container css={containerStyle}>{children}</Container>
      <Footer hide={hideFooter} />
    </ThemeProvider>
  </I18nextProvider>
)

Layout.propTypes = propTypes
Layout.displayName = 'Layout'

export { Layout }
