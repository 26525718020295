/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Footer as FooterCreditas, FooterGroup } from '@creditas/footer'
import { Typography } from '@creditas/typography'

const propTypes = {
  hide: bool,
}

const Footer = ({ hide = false }) => {
  const { t } = useTranslation()

  const currentYear = new Date().getFullYear()

  const copyrightPhrase = `${t('company')} ${currentYear}. ${t(
    'phrases.copyright',
  )}`

  return (
    !hide && (
      <FooterCreditas data-testid="footer" variant="internal">
        <FooterGroup>
          <Typography variant="support">{copyrightPhrase}</Typography>
        </FooterGroup>
      </FooterCreditas>
    )
  )
}

Footer.propTypes = propTypes

export { Footer }
