/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { bool } from 'prop-types'
import { Logo } from './Logo'
import { HeaderContainer } from './header.style'

const propTypes = {
  hide: bool,
}

const Header = ({ hide = false }) =>
  !hide && (
    <HeaderContainer data-testid="header">
      <Logo />
    </HeaderContainer>
  )

Header.propTypes = propTypes
Header.displayName = 'Header'

export { Header }
