import { styled } from '@creditas/stylitas'

export const HeaderContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 18px 20px;
  border-bottom: 2px solid #edf0ef;
  max-height: 55px;
`
